import React from "react"

const NotFound = () => {
  return (
    <div>
      <h1>Oops! Page not found :(</h1>
    </div>
  )
}

export default NotFound
